/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Imports the icons */
@import '~css.gg/icons/all.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

lib-fulltext-counter.insideMenu {
  p {
    margin: 0px !important;
  }
}

.b_valid {
  border-right: var(--ion-color-success) solid 6px !important;
}

.b_invalid {
  border-right: var(--ion-color-danger) solid 6px !important;
}

mits-object-select.b_valid,
mits-boilerplates.b_valid {
  border-right: none !important;

  ion-item {
    border-right: var(--ion-color-success) solid 6px;
  }
}

mits-object-select.b_invalid,
mits-boilerplates.b_invalid {
  border-right: none !important;

  ion-item {
    border-right: var(--ion-color-danger) solid 6px;
  }
}

mits-article-list.b_valid {
  border-right: none !important;

  .mitsArticleList {
    border-right: var(--ion-color-success) solid 6px !important;
  }
}

mits-article-list.b_invalid {
  border-right: none !important;

  .mitsArticleList {
    border-right: var(--ion-color-danger) solid 6px !important;
  }
}

mits-files-upload-select.b_valid {
  border-right: none !important;

  .mitsUploadSelectImagesWrapper {
    border-right: var(--ion-color-success) solid 6px !important;
  }
}

mits-files-upload-select.b_invalid {
  border-right: none !important;

  .mitsUploadSelectImagesWrapper {
    border-right: var(--ion-color-danger) solid 6px !important;
  }
}

ion-card {
  --background: #ffffff;
  border: solid 1px #e5e6ea;
  box-shadow: none;
  border-radius: 0;
  margin-inline: 0;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

// TODO: In zukunft wieder reverten
// BUGFIX für die datetime-picker dort wird bei klick nicht der picker dargestellt sondern nur der backdrop
div.ion-page {
  display: contents;
}

ion-content {
  --background: var(--ion-color-medium);
}

ion-footer {
  background-color: var(--ion-color-light);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--ion-color-light-shade);
}

::-webkit-scrollbar-thumb {
  background: var(--ion-color-tertiary);
  border-radius: 10px;

  &:hover {
    background: var(--ion-color-tertiary-shade);
  }
}

.flexboxCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

ion-alert {
  text-align: center !important;
  color: var(--ion-color-tertiary) !important;
  font-size: large !important;
}

.clickable {
  cursor: pointer;
}

/** Image-Drawing Component */
image-drawing {
  .toolbar {
    justify-content: center !important;

    .tools {
      padding: 10px !important;
    }

    .buttons {
      margin: 0px !important;
      flex-direction: row !important;
    }
  }
}

ion-label {
  --color: var(--ion-color-tertiary-shade) !important;
}

// Wenn ein Item alle Button in voller größe darstellen soll (wie sliding buttons)
.fullSizeButtonItem {
  --inner-padding-end: 0px;
  ion-button {
    height: 100% !important;
    margin: 0px !important;
    min-width: 60px;
  }
}

// Suche für z.B. cars oder Machines welche in einer eigenen Card liegt
.cardSearch {
  & > ion-card-content {
    & > input {
      border: none;
      border-radius: 3px;
      height: 36px;
      outline: none;
      width: 100%;
      color: var(--ion-color-primary);
    }
    & > ion-button {
      position: absolute;
      height: 36px;
      top: 8px;
      right: 16px;
    }
  }
}

@mixin card {
  padding-bottom: 1.25rem;
}

@mixin mobile-card {
  @include card;
  margin: 0rem;
}

@mixin titles {
  padding-top: 1.5625rem;
  --color: 'primary';
  font-size: x-large;
}

@mixin item-height {
  --min-height: 4.688rem !important;
}

@mixin checkbox {
  --background: none;
  --background-checked: none;
  --border-color-checked: var(--ion-color-tertiary-shade) !important;
  --checkmark-color: var(--ion-color-tertiary-shade) !important;
  --border-width: 0.0625rem;
}

@mixin apple-item {
  @include item-height;
  transform: translate3d(-9px, 0px, 0px) !important;
}

@mixin other-datepicker {
  --min-height: 4.788rem;
  --padding-start: 0;
  --inner-padding-start: 1rem;
}

.core {
  @include card;

  & > ion-card-title {
    padding-left: 1.88rem;
    padding-bottom: 0.3125rem;
    @include titles;
  }

  & > ion-card-subtitle {
    padding-left: 1.9rem;
  }

  & > ion-card-content {
    padding: 0.625rem;

    & > ion-card-subtitle {
      padding-left: 1.3rem;
    }

    & .main-wrapper {
      & ion-item {
        @include item-height;

        & ion-checkbox {
          @include checkbox;
        }
      }
    }
  }

  .other-datepicker {
    @include other-datepicker;
  }
}

.apple {
  @include mobile-card;

  & > ion-card-title {
    padding-left: 0.95rem;
    padding-bottom: 0;
    @include titles;
  }

  & > ion-card-subtitle {
    padding-left: 1rem;
  }

  .subtitle-top {
    padding-top: 2.25rem;
  }

  & > ion-card-content {
    padding: 0;

    & > ion-card-subtitle {
      padding-left: 1rem;
    }

    & .main-wrapper {
      & ion-item {
        @include apple-item;

        & ion-checkbox {
          @include checkbox;
        }
      }
    }

    & .selectable-wrapper {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0.3125rem;
      padding-right: 0.3125rem;

      & > ion-item {
        @include apple-item;
      }
    }
  }

  .apple-datepicker {
    transform: translate3d(-9px, 0px, 0px) !important;
    margin: 0;
  }
}

.android {
  @include mobile-card;

  & > ion-card-title {
    padding-left: 1.25rem;
    padding-bottom: 0.625rem;
    @include titles;
  }

  & > ion-card-subtitle {
    padding-left: 1.3rem;
  }

  & > ion-card-content {
    padding: 0;

    & > ion-card-subtitle {
      padding-left: 1.3rem;
    }

    & .main-wrapper {
      & ion-item {
        @include item-height;

        & ion-checkbox {
          @include checkbox;
        }
      }
    }
  }

  .other-datepicker {
    @include other-datepicker;
  }
}

.position-modal {
  --width: 400px !important;
  --height: 410px !important;
  --max-height: 100% !important;
}

.finish-wizard-modal {
  --width: 700px !important;
  --height: 900px !important;
  --max-height: 100% !important;
}

.order-writeable-checklist-modal {
  --width: 700px !important;
  --height: 900px !important;
  --max-height: 100% !important;
}

/** Needed for scanner component*/
video {
  position: relative;
  min-width: 300px !important;
  min-height: 700px !important;
}

/* DetailPages Ende */
.debug {
  border-width: 1px;
  border-style: solid;

  * {
    border-width: 1px;
    border-style: dashed;
  }

  border-color: rgb(255, 0, 0);

  & > * {
    border-color: rgb(0, 125, 0);

    & > * {
      border-color: rgb(0, 0, 255);

      & > * {
        border-color: rgb(255, 155, 125);

        & > * {
          border-color: rgb(0, 125, 125);

          & > * {
            border-color: rgb(255, 0, 255);

            & > * {
              border-color: rgb(125, 125, 0);

              & > * {
                border-color: rgb(125, 0, 255);
              }
            }
          }
        }
      }
    }
  }
}

ion-button.hasMitsIcon {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 8px;
  --padding-bottom: 8px;
}

// ion-accordion on template-page
ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      font-size: 20px !important;
      color: #6d6d6e !important;
    }
  }
}

ion-accordion {
  margin: 0 auto;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(100% - 12px);
  margin: 16px auto;
}

mits-selectable {
  display: list-item;
}
